<template>
    <div>
        <div class="d-flex flex-column flex-md-row justify-content-md-between">
            <div class="d-flex">
                <h1>Perfil de usuario</h1>
                <button
                    type="button"
                    class="btn btn-link"
                    @click="generateReportErrors"
                >
                    👩
                </button>
            </div>
            <div class="d-flex align-items-center">
                <vs-button circle floating class="my-0" @click="reloadItem">
                    <reload-icon />
                </vs-button>
                <vs-switch
                    v-model="enabledUpdate"
                    :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
                >
                    🖊
                </vs-switch>
            </div>
        </div>
        <div class="d-flex flex-wrap mt-3">
            <h2 class="h3">Información ingreso</h2>
        </div>
        <div class="row mt-2">
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.id"
                    shadow
                    class="w-100 opacity-false"
                    label="Id"
                    disabled
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.email"
                    shadow
                    class="w-100 opacity-false"
                    label="Correo electrónico"
                    disabled
                />
            </div>
        </div>
        <div class="d-flex flex-wrap mt-3">
            <h2 class="h3">Información básica</h2>
        </div>
        <div class="row mt-2">
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.firstName"
                    border
                    :state="stateEnabled"
                    class="w-100 opacity-false"
                    shadow
                    label="Nombres"
                    :disabled="!enabledUpdate"
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.lastName"
                    border
                    :state="stateEnabled"
                    shadow
                    label="Apellidos"
                    class="w-100 opacity-false"
                    :disabled="!enabledUpdate"
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <identification-type-selector
                    v-model="itemComponent.identificationType"
                    :enabled="enabledUpdate"
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.identificationNumber"
                    border
                    :state="stateEnabled"
                    shadow
                    label="Número de Identificación"
                    class="w-100 opacity-false"
                    :disabled="!enabledUpdate"
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.phone"
                    border
                    :state="stateEnabled"
                    shadow
                    label="Celular de contacto"
                    class="w-100 opacity-false"
                    type="number"
                    :disabled="!enabledUpdate"
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <city-code-selector
                    v-model="itemComponent.cityCode"
                    :enabled="enabledUpdate"
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.address"
                    border
                    :state="stateEnabled"
                    shadow
                    label="Dirección"
                    class="w-100 opacity-false"
                    :disabled="!enabledUpdate"
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.addressComplement"
                    border
                    :state="stateEnabled"
                    shadow
                    label="Complemento de dirección"
                    class="w-100 opacity-false"
                    :disabled="!enabledUpdate"
                />
            </div>
            <div class="col-12 col-md-6 mt-4 d-flex flex-column my-2">
                <vs-input
                    v-model="itemComponent.neighborhood"
                    border
                    :state="stateEnabled"
                    shadow
                    label="Barrio"
                    class="w-100 opacity-false"
                    :disabled="!enabledUpdate"
                />
            </div>
        </div>
        <div class="d-flex justify-content-center my-4">
            <vs-button :disabled="!enabledUpdate" @click="updateBasicDataUser">
                Actualizar información
            </vs-button>
        </div>
        <div class="d-flex flex-wrap mt-4">
            <h2 class="h3">Comercios con acceso.</h2>
            <vs-card-group>
                <vs-card v-for="item in merchantsOwn" :key="item.id">
                    <template #title>
                        <h3>
                            {{ item.type | merchantInfo("icon") }}
                            {{ item.name }}
                        </h3>
                    </template>
                    <template #text>
                        <p>🗺 {{ item.address }}</p>
                    </template>
                </vs-card>
            </vs-card-group>
        </div>

        <div class="d-flex flex-wrap mt-4">
            <h2 class="h3">Roles.</h2>
            <div class="col-12">
                <div
                    v-for="(item, index) in roles"
                    :key="index"
                    class="d-flex align-items-center my-1"
                >
                    <vs-avatar
                        color="#d3d4f9"
                        :badge="index === 0"
                        badge-color="danger"
                        size="30"
                    >
                        {{ $data.$rolesEmoji[item.slug] || "👷‍♂️" }}
                    </vs-avatar>
                    <strong class="ml-3 text-capitalize">
                        {{ item.slug }}
                    </strong>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { rolesEmoji } from "@/common/fields/roles";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";
import IdentificationTypeSelector from "@/components/users/IdentificationTypeSelector.vue";
import CityCodeSelector from "@/components/utils/CityCodeSelector.vue";

export default {
    name: "ProfilePage",
    components: { IdentificationTypeSelector, CityCodeSelector, ReloadIcon },
    data: () => ({
        enabledUpdate: false,
        itemComponent: {
            identificationType: "cc",
            identificationNumber: "",
            username: "",
            firstName: "",
            lastName: "",
            cityCode: "11001",
            address: "",
            neighborhood: "",
            email: "",
            phone: ""
        },
        introValidators: {
            phone: false,
            identificationNumber: false,
            firstName: false,
            lastName: false
        },
        loaderInstance: null,
        isLoading: true,
        $rolesEmoji: rolesEmoji
    }),
    computed: {
        ...mapState("auth", ["user", "roles"]),
        ...mapState("commons", ["merchantsOwn"]),
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    async mounted() {
        this.itemComponent = { ...this.user };
        if (!this.itemComponent.identificationNumber) {
            this.reloadItem(true);
        }
    },
    methods: {
        ...mapActions("auth", ["getProfile", "updateProfile"]),
        async updateBasicDataUser() {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "points" });
            try {
                await this.updateProfile(this.itemComponent);
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async reloadItem(complete = false) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({
                type: "points",
                color: "#f07325"
            });
            try {
                this.itemComponent = await this.getProfile({ complete });
                this.loaderInstance.close();
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        async generateReportErrors() {
            const dialogResult = await this.$swal.fire({
                title: "Deseas generar reporte de errores",
                type: "info",
                showCancelButton: true,
                confirmButtonColor: "#253C99",
                cancelButtonColor: "#02913f",
                confirmButtonText: "Si"
            });
            if (dialogResult.isConfirmed) {
                console.log("TODO generate report");
            }
        }
    }
};
</script>
